.nav-container {
  background-image: url("../../../images/toy-fair-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.logo-title-container {
  margin-top: -1rem;
  color: green;
  padding: 0.5rem;
  font-family: Helvetica, Arial, Sans-Serif;
}

.logo-title {
  font-weight: 900;
  font-size: 36px;
  text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);
  color: #3fb93b;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu,
    Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-shadow: 2px 2px #000;
  letter-spacing: 4px;
}

.logo-subtitle {
  font-weight: bold;
  font-size: 16px;
  background-color: #6f6f6f;
  padding: 2px 5px;
  color: white;
  letter-spacing: 2px;
}

.brand-logo {
  width: 60px;
}

@media only screen and (max-width: 767px) {
  .nav-col {
    display: flex;
    flex: 0 1 0;
    flex-direction: column;
  }

  .nav-container {
    height: auto;
  }

  .brand-logo {
    max-width: 60px;
    max-height: 60px;
    width: auto;
    height: auto;
  }

  .nav-brand-container {
    height: 100%;
  }

  .logo-title {
    font-size: 24px;
    letter-spacing: 2px;
    display: inline-block !important;
  }

  .logo-subtitle {
    display: inline-block !important;
    font-weight: bold;
    font-size: 12px;
    background-color: #6f6f6f;
    padding: 2px 4px;
  }
}
