.global-chatbot {
  box-shadow: rgba(0, 0, 0, 0.15) 0px 12px 24px 0px;
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
  transition: transform 0.3s ease 0s;
}